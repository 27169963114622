import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function opportunitiesReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PAYMENT_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isReadingFormToken: true, token: null };
        case getNamedAction(ACTION_NAMES.PAYMENT_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isReadingFormToken: false, token: action.data.token };
        case getNamedAction(ACTION_NAMES.PAYMENT_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isReadingFormToken: false, token: null };

        case getNamedAction(ACTION_NAMES.PAYMENT_CREATE_CUSTOMER_PROFILE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isReadingFormToken: true, customerProfileId: null };
        case getNamedAction(ACTION_NAMES.PAYMENT_CREATE_CUSTOMER_PROFILE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isReadingFormToken: false, customerProfileId: action.data.customerProfileId };
        case getNamedAction(ACTION_NAMES.PAYMENT_CREATE_CUSTOMER_PROFILE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isReadingFormToken: false, customerProfileId: null };

        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingProfile: true };
        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingProfile: false, balance: action.data.balance || 0, profiles: (action.data.profiles || { data: [] }).data };
        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingProfile: false };

        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isSavingProfile: true };
        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isSavingProfile: false };
        case getNamedAction(ACTION_NAMES.PAYMENT_PROFILE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isSavingProfile: false };

        case getNamedAction(ACTION_NAMES.FUND_PAYMENT_PROFILE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isSavingProfile: true };
        case getNamedAction(ACTION_NAMES.FUND_PAYMENT_PROFILE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isSavingProfile: false };
        case getNamedAction(ACTION_NAMES.FUND_PAYMENT_PROFILE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isSavingProfile: false };

        case getNamedAction(ACTION_NAMES.DEACTIVATE_PAYMENT_PROFILE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isDeactivatingProfile: true };
        case getNamedAction(ACTION_NAMES.DEACTIVATE_PAYMENT_PROFILE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isDeactivatingProfile: false };
        case getNamedAction(ACTION_NAMES.DEACTIVATE_PAYMENT_PROFILE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isDeactivatingProfile: false };

        case getNamedAction(ACTION_NAMES.ACCEPT_CUSTOMER_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isReadingCustomerFormToken: true, customerFormToken: null };
        case getNamedAction(ACTION_NAMES.ACCEPT_CUSTOMER_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isReadingCustomerFormToken: false, customerFormToken: action.data.token };
        case getNamedAction(ACTION_NAMES.ACCEPT_CUSTOMER_FORM_TOKEN, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isReadingCustomerFormToken: false, customerFormToken: null };

        default:
            return state;
    }
}


export default opportunitiesReducer;
