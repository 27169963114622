import { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Form, Radio, Segment } from "semantic-ui-react";

const ExternalPaymentMethod = ({ form_token }) => {
    const payElement = useRef();
    const iframeRef = useRef();

    useEffect(() => {
        payElement.current?.submit({ target: "add_payment" });

        const parseQueryString = (str) => {
            const vars = {};
            const arr = str.split("&");
            arr.forEach((pair) => {
                const [key, value] = pair.split("=");
                vars[key] = decodeURIComponent(value);
            });
            return vars;
        };

        const handleReceiveCommunication = (event) => {
            const allowedOrigin = "https://test.authorize.net"; // Use 'https://accept.authorize.net' in production
            if (event.origin !== allowedOrigin) return;

            console.log(event);

            const params = parseQueryString(event.data);

            switch (params["action"]) {
                case "successfulSave":
                    // Handle successful save
                    break;
                case "cancel":
                    // Handle cancel action
                    break;
                case "resizeWindow":
                    const iframe = iframeRef.current;
                    if (iframe) {
                        iframe.style.width = `${params["width"]}px`;
                        iframe.style.height = `${params["height"]}px`;
                    }
                    break;
                case "transactResponse":
                    if (iframeRef.current) {
                        iframeRef.current.style.display = "none";
                    }
                    break;
                default:
                    console.log("Unknown action:", params["action"]);
            }
        };

        window.addEventListener("message", handleReceiveCommunication);

        return () => {
            window.removeEventListener("message", handleReceiveCommunication);
        };
    }, []);

    return (
        <>
            <form
                ref={payElement}
                target="add_payment"
                method="post"
                action="https://test.authorize.net/customer/addPayment"
            >
                <input type="hidden" name="token" value={form_token} />
            </form>
            <div
                id="iframe_holder"
                class="center-block"
                style={{ width: "100%", maxWidth: "1000px", height: "50vh" }}
            >
                <iframe
                    ref={iframeRef}
                    title="Add Payment"
                    id="add_payment"
                    class="embed-responsive-item panel"
                    name="add_payment"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    style={{ overflow: "hidden" }}
                ></iframe>
            </div>
        </>
    );
};

const PaymentMethod = ({
    selectedPaymentMethod,
    onChangeSelectedPaymentMethod,
}) => {
    const isActive = (method) => {
        return selectedPaymentMethod === method;
    };

    const getClassName = (method) => {
        return `PaymentMethod${
            isActive(method) ? ` PaymentMethod--active` : ""
        }`;
    };

    const { control } = useFormContext();

    const { isReadingFormToken, form_token } = useSelector((state) => ({
        isReadingFormToken: state.payment.isReadingCustomerFormToken,
        form_token: state.payment.customerFormToken,
    }));

    return (
        <Segment.Group>
            <Segment className={getClassName("bank")} compact>
                <Radio
                    label="Credit Card/E-Check"
                    checked={isActive("bank")}
                    onChange={() => {
                        onChangeSelectedPaymentMethod("bank");
                    }}
                />
            </Segment>
            {isReadingFormToken && (
                <Segment
                    style={{ height: "5vh" }}
                    loading={isReadingFormToken}
                />
            )}
            <Segment
                {...(!(isActive("bank") && !isReadingFormToken)
                    ? { style: { display: "none" } }
                    : {})}
            >
                {form_token && (
                    <ExternalPaymentMethod form_token={form_token} />
                )}
            </Segment>
            <Segment className={getClassName("custom-ach")} compact>
                <Radio
                    label="Custom (ACH)"
                    checked={isActive("custom-ach")}
                    onChange={() => {
                        onChangeSelectedPaymentMethod("custom-ach");
                    }}
                />
            </Segment>
            {selectedPaymentMethod === "custom-ach" && (
                <Segment style={{ minHeight: "11em" }}>
                    <Form noValidate>
                        <Controller
                            name="custom_ach"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <Form.TextArea
                                    placeholder="Insert account payable information (bank, account #)"
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    error={error?.message}
                                />
                            )}
                        />
                    </Form>
                </Segment>
            )}
        </Segment.Group>
    );
};

export default PaymentMethod;
