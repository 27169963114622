import ACTION_NAMES from '../ACTION_NAMES';
import routes from '../routes';
import { generateCreateActionCreator, generateReadOneActionCreator } from '../crudActionCreators';

export default {
    generateFormToken: generateCreateActionCreator(`${routes.API_PAYMENT}/form_token`, ACTION_NAMES.PAYMENT_FORM_TOKEN),
    generateAcceptCustomerFormToken: generateCreateActionCreator(`${routes.API_PAYMENT}/accept_customer_form_token`, ACTION_NAMES.ACCEPT_CUSTOMER_FORM_TOKEN),
    createCustomerProfile: generateCreateActionCreator(`${routes.API_PAYMENT}/create_customer_profile`, ACTION_NAMES.PAYMENT_CREATE_CUSTOMER_PROFILE),
    getProfiles: generateReadOneActionCreator(`${routes.API_PAYMENT}/customer`, ACTION_NAMES.PAYMENT_PROFILE),
}
