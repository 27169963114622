import { Button, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import PaymentInformation from "./PaymentInformation";
import { Modal, Step } from "dyl-components";
import { Controller, useFormContext } from "react-hook-form";
import { useContext, useState } from "react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import PaymentMethod from "./PaymentMethod";

import "./index.scss";
import { useDispatch } from "react-redux";
import paymentActions from "actions/payment";

const STEPS = [
    {
        icon: <Icon className="fas fa-box-dollar" size="large" />,
        title: "Order",
        completed: true,
    },
    {
        icon: <Icon className="fas fa-file-invoice-dollar" size="large" />,
        title: "Checkout",
        active: true,
    },
];

const Payment = ({ account_id }) => {
    const {
        formState: { control, isValid, isDirty },
    } = useFormContext();
    const { onViewOrder, quoteBuilderConfig } = useContext(QuoteBuilderContext);
    const dispatch = useDispatch();

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const onChangeSelectedPaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
        if (method === "bank") {
            const host = `http${window.location.host === "localhost:3001" ? "" : "s"}://${window.location.host}`;
            dispatch(
                paymentActions.generateAcceptCustomerFormToken({
                    customerProfileId: "519680338", // TODO: replace this with the customer profile ID retrieved from the backend
                    hostedProfileSettings: [
                        {
                            settingName: "hostedProfileSaveButtonText",
                            settingValue: `Add`
                        },
                        {
                            settingName: "hostedProfilePageBorderVisible",
                            settingValue: `false`
                        },
                        {
                            settingName: "hostedProfileHeadingBgColor",
                            settingValue: `#2B78FF`
                        },
                        {
                            settingName: "hostedProfileIFrameCommunicatorUrl",
                            settingValue: `${host}/IFrameCommunicator.html`
                        },
                        {
                            settingName: "hostedProfileValidationMode",
                            settingValue: "testMode"
                        },
                        {
                            settingName: "hostedProfileBillingAddressOptions",
                            settingValue: "showNone"
                        },
                        {
                            settingName: "hostedProfileManageOptions",
                            settingValue: "showPayment"
                        }
                    ]
                })
            );
        }
    };

    return (
        <>
            <Modal.Content scrolling>
                <Form size="small" noValidate>
                    <Segment size="tiny" basic>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Step.Group horizontal>
                                        {STEPS.map(({ icon, ...step }) => (
                                            <Step {...step} key={step.name}>
                                                {icon}
                                            </Step>
                                        ))}
                                    </Step.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header color="primary">
                                        Invoice Information
                                    </Header>
                                    <PaymentInformation />
                                    <Header color="primary">
                                        Payment Method
                                    </Header>
                                    <PaymentMethod
                                        onChangeSelectedPaymentMethod={
                                            onChangeSelectedPaymentMethod
                                        }
                                        selectedPaymentMethod={
                                            selectedPaymentMethod
                                        }
                                    />
                                    <Controller
                                        control={control}
                                        name="buyer_notes"
                                        render={({
                                            field: { name, value, onChange },
                                        }) => (
                                            <Form.TextArea
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                label="Buyer Notes"
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    basic
                    onClick={() => {
                        onViewOrder(quoteBuilderConfig?.id, account_id);
                    }}
                    type="button"
                    color="primary"
                >
                    Back to Order
                </Button>
                <Button
                    disabled={!isValid || !isDirty}
                    type="submit"
                    color="primary"
                >
                    Process
                </Button>
            </Modal.Actions>
        </>
    );
};

export default Payment;
